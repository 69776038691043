import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Logo from 'components/Shared/Logo/Logo';
import NotFound from 'components/Pages/404';

import { GAME_PATH, GAMES } from 'components/Games/settings';
import Navigation from 'components/Shared/Navigation/Navigation';


function* generateId() {
  let id = 0;

  while (true) {
    id++;
    yield id;
  }
}

const getId = generateId();

const AliasMenu = React.lazy(() => import('../Shared/MenuSelection/alias/AliasMenu'));
const AliasGame = React.lazy(() => import('../Games/AliasGame'));
const TabooMenu = React.lazy(() => import('../Shared/MenuSelection/taboo/TabooMenu'));
const TabooGame = React.lazy(() => import('../Games/TabooGame'));

const Component = (Component) => (
  <React.Suspense>
    <Component />
    <Navigation id={getId.next().value} />
  </React.Suspense>
)

const PageRoutes = () => {
  return (
    <div className="App">
      <Routes>
        <Route
          index
          element={Component(Logo)}
        />
        <Route path={GAME_PATH}>
          <Route path={GAMES.AliasGame}>
            <Route
              path="menu"
              element={Component(AliasMenu)}
            />
            <Route
              path="start"
              element={Component(AliasGame)}
            />
          </Route>
          <Route path={GAMES.Taboo}>
            <Route
              path="menu"
              element={Component(TabooMenu)}
            />
            <Route
              path="start"
              element={Component(TabooGame)}
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default PageRoutes;
