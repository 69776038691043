import React from 'react';
import { ToastContainer } from 'react-toastify';

import PageRoutes from 'components/Routes/Routes';
import GamesMenu from 'components/Shared/GamesMenu/GamesMenu';

import { GlobalContext } from 'context/global.context';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [gamesModalOpen, setGamesModalOpen] = React.useState(false);

  const setModal = React.useCallback((value) => {
    setGamesModalOpen(value);
  }, [setGamesModalOpen]);

  return (
    <>
      <GamesMenu
        open={gamesModalOpen} 
        setClose={() => setModal(false)}
      />
      <GlobalContext.Provider
        value={{
          modalOpen: gamesModalOpen,
          setModal
        }}
      >
        <PageRoutes />
      </GlobalContext.Provider>
      <ToastContainer />
    </>
  );
}

export default App;
