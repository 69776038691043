import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Text as T } from '../Text/Text';
import { Icon } from '../Icon/Icon';
import Modal from '../Modal/Modal';

import { ALL_GAMES } from 'components/Games/settings';

import { useOnClickOutside } from 'hooks/useOutsideClick';

import './GamesMenu.css';

const GamesMenu = ({ open, setClose }) => {
  const ref = React.useRef(null);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const closeModal = () => {
    if (isHomePage) return;

    setClose();
  }

  useOnClickOutside(ref, closeModal, 'click', null, ['games-icon']);

  return (
    <Modal handleClose={setClose} isOpen={open}>
      <div ref={ref} className='games-container'>
        <div className='games-header'>
          <Text>
            All Games
          </Text>
          { !isHomePage && <Icon onClick={setClose} style={{ borderRadius: '50%', width: '23px', height: '23px' }} src="./images/icons/close.svg" /> }
        </div>
        <div className='games-content'>
          {
            ALL_GAMES.map((game) => {
              return (
                <Link
                  key={game.id}
                  to={game.path}
                  onClick={setClose}
                  style={{textDecoration: 'none'}}
                  className={`${game?.tag === 'soon' && 'disabled-container'}`}
                >
                  <div className={`game-container`}>
                    <img
                      className='game-thumb-img'
                      src={game.thumbImage}
                      alt={game.name}
                    />
                    {
                      game?.tag !== 'soon' && (
                        <Text>
                          Play
                        </Text>
                      )
                    }
                    {
                      game?.tag === 'soon' && (
                        <>
                          <Soon>
                            Soon
                          </Soon>
                          <div className='soon-container'>
                            Soon
                          </div>
                        </>
                      )
                    }
                    {
                      game?.tag === 'new' && (
                        <div className='new-container'>
                          New
                        </div>
                      )
                    }
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </Modal>
  );
};

const Text = styled(T)`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;
const Soon = styled(Text)`
  color: #707373;
`;
const New = styled(Text)`
  color: #ffffff;
`;

export default GamesMenu;