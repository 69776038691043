import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import ReactPortal from "components/Shared/ReactPortal/ReactPortal";

import Button from "../Button/Button";
import { Icon } from "../Icon/Icon";

import { useGlobalContext } from "context/global.context";

import './Navigation.css';

function Navigation({ id }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setModal } = useGlobalContext();

  // ["","game","alias","menu"]
  const pathname = location.pathname?.split('/');
  const isGameStarted = pathname?.at(1) === 'game' && pathname?.at(3) === 'start';
  const navBlockClass = `navigation-block ${!isGameStarted && 'without-back-button'}`;

  const handleBack = () => {
    if (!isGameStarted) return;

    navigate(location.pathname.replace('start', 'menu'));
  }

	return (
		<ReactPortal wrapperId={`react-portal-navigation-container-${id}`}>
      <div className="navigation-container">
        <div className={navBlockClass}>
          {
            isGameStarted && (
              <BackButton onClick={handleBack}>
                <Icon src={`${process.env.REACT_APP_HOME_PAGE}/images/icons/back_home.svg`} alt='apps' style={{width: '20px', height: '20px'}} />
              </BackButton>
            )
          }
          <Icon
            id="games-icon"
            onClick={() => setModal(true)}
            src={`${process.env.REACT_APP_HOME_PAGE}/images/icons/apps.svg`}
            alt='apps'
            style={{width: '24px', height: '24px', color: '#fff'}}
          />
        </div>
      </div>
		</ReactPortal>
	);
}

const BackButton = styled(Button)`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Navigation;