export const GAMES = {
  AliasGame: 'alias',
  Taboo: 'taboo',
  WordScramble: 'word-scramble',
};
export const GAME_STAGES = {
  MENU: 'menu',
  GAME: 'game',
};
export const GAME_PATH = `game`;

export const getGamePath = (id) => `/${GAME_PATH}/${id}`;

export const ALL_GAMES = [
  {
    name: 'Alias',
    id: GAMES.AliasGame,
    thumbImage: `${process.env.REACT_APP_HOME_PAGE}/images/cropped_192.png`,
    path: `${getGamePath(GAMES.AliasGame)}/menu`,
  },
  {
    name: 'Taboo',
    id: GAMES.Taboo,
    tag: 'new',
    thumbImage: `${process.env.REACT_APP_HOME_PAGE}/images/taboo_2_192.png`,
    path: `${getGamePath(GAMES.Taboo)}/menu`,
  },
  {
    name: 'Word Scramble',
    id: GAMES.WordScramble,
    tag: 'soon',
    thumbImage: `${process.env.REACT_APP_HOME_PAGE}/images/word-scramble_192.png`,
    path: `${getGamePath(GAMES.WordScramble)}/menu`,
  }
];