import { useEventListener } from './useEventListener'

/**
 * @param ref - The React ref object(s) representing the element(s) to watch for outside clicks.
 * @param {(event: MouseEvent | TouchEvent | FocusEvent) => void} handler - The callback function to be executed when a click outside the element occurs.
 * @param {EventType} [eventType] - The mouse event type to listen for (optional, default is 'mousedown').
 * @param {?AddEventListenerOptions} [eventListenerOptions] - The options object to be passed to the `addEventListener` method (optional).
 * @returns {void}
 * @public
 * @example
 * ```tsx
 * const containerRef = useRef(null);
 * useOnClickOutside([containerRef], () => {
 *   // Handle clicks outside the container.
 * });
 * ```
 */
export function useOnClickOutside(
  ref,
  handler,
  eventType,
  eventListenerOptions,
  exceptions,
) {
  useEventListener(
    eventType,
    event => {
      const target = event.target;

      // Do nothing if the target is not connected element with document
      if (!target || !target.isConnected) {
        return
      }
      if (exceptions?.length && exceptions.some((e) => target.id?.includes(e))) return;

      const isOutside = Array.isArray(ref)
        ? ref
            .filter(r => Boolean(r.current))
            .every(r => r.current && !r.current.contains(target))
        : ref.current && !ref.current.contains(target)

      if (isOutside) {
        handler(event)
      }
    },
    undefined,
    eventListenerOptions,
  )
}